import useTargetAudience, { Audience } from 'hooks/use-target-audience'
import { useExperimentSplit } from 'utilities/experiment/context'
import CmsPageContext from 'contexts/cms-page-context'
import { useContext } from 'react'
import { useRouter } from 'next/router'

const VARIANT_B_AMOUNT = 10
const VARIANT_C_AMOUNT = 20

export const useIsPaidTrial = ({ isReengagement = false } = {}) => {
  const targetAudience = useTargetAudience()
  const cmsPageContext = useContext(CmsPageContext)

  const { query } = useRouter()
  const isFreeTrial =
    targetAudience === Audience.FreeTrial ||
    targetAudience === Audience.CoachFreeTrial
  const isGuestPass = targetAudience === Audience.GuestPass
  const isTYPGuestPass = targetAudience === Audience.ThankYouPageGuestPass
  const isCoachRefFreeTrial = targetAudience === Audience.CoachFreeTrial
  const hasCoupon = !!query.couponCode || !!cmsPageContext.coupon
  const isCoachAcquired = !!query.referredByCoachId
  const isFacebookAd = !!query.fbclid

  const shouldForceControl =
    isFreeTrial ||
    isGuestPass ||
    isTYPGuestPass ||
    hasCoupon ||
    isCoachRefFreeTrial ||
    isCoachAcquired ||
    isReengagement

  const shouldForceExperiment = isFacebookAd
  const paidTrialSplit = useExperimentSplit(
    'paidTrial',
    shouldForceControl ? 'A' : shouldForceExperiment ? 'C' : undefined
  )
  let paidTrialAmount
  if (paidTrialSplit === 'B') {
    paidTrialAmount = VARIANT_B_AMOUNT
  }
  if (paidTrialSplit === 'C') {
    paidTrialAmount = VARIANT_C_AMOUNT
  }

  return {
    split: paidTrialSplit,
    isPaidTrial: paidTrialSplit === 'B' || paidTrialSplit === 'C',
    paidTrialAmount,
  }
}
